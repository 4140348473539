<template>
  <div>
      <div style="padding: 25px 0px" class="text-center">
        <a href="#" class="log">
          <img src="@/assets/images/logo-dark.png" height="60" alt="logo" />
        </a>
      </div>
      <b-card>
        <div class="card-body">
          <div>
            <h5 class="text-center">
              <span class="font-weight-bold" style="text-transform: uppercase"
                >Carefully go through these Terms of Use before you submit your
                application.</span
              >
            </h5>
            <p><span></span></p>
            <p><span></span></p>
            <h6><span>ACCEPTANCE</span></h6>
            <p class="c2">
              <span
                >By using or accessing any part of this website or the services
                provided on it, you agree to accept and comply with the terms of
                use stated herein and as may be modified by us without notice to
                you. These conditions, notices, and terms (Terms of Use)
                constitute a binding contract between you and CadLands. It is
                your sole responsibility to regularly check for updates and
                review the Terms of Use. If you do not accept any of our
                conditions or you do not wish to be bound by these Terms of Use,
                please do not make use of or access any part of our
                Services.</span
              >
            </p>
            <p><span></span></p>
            <h6><span>LEGAL USE</span></h6>
            <p class="c2">
              <span
                >We offer you access to our website and services solely for your
                personal and non-commercial uses, unless stated otherwise. You
                agree to access or use the CadLands Affiliate website only for
                purposes that are legal and as permitted by the Terms of Use.
                Among other things, you agree not to:</span
              >
            </p>
            <ol class="c5 lst-kix_7ynucli674o6-0 start" start="1">
              <li class="c3 li-bullet-0">
                <span
                  >Undertake in any activity that may misrepresent CadLands, its
                  services or beliefs, and what the company stands for. This
                  also includes altering any information contained on any
                  marketing collateral or material without the express
                  permission of the company.</span
                >
              </li>
              <li class="c2 c6 li-bullet-0">
                <span
                  >Obtain or attempt to obtain any confidential information or
                  material from the website through any means not made readily
                  accessible or approved by CadLands. This also includes any
                  attempt to steal or hijack someone else&rsquo;s client or new
                  affiliate.</span
                >
              </li>
              <li class="c3 li-bullet-0">
                <span
                  >Present false or incomplete information on the website. All
                  information contained on your profile must be true and match
                  the information on your bank details or official
                  documents.</span
                >
              </li>
              <li class="c3 li-bullet-0">
                <span
                  >Collect or receive any unauthorized payment on behalf of
                  CadLands. All payments should be directed to CadLands official
                  bank accounts.</span
                >
              </li>
            </ol>
            <p><span></span></p>
            <p>
              <span
                >You agree that CadLands reserves the express right to terminate
                your access to and use of our website with or without notice,
                and at any time. You further agree that your use of our website
                and any of our services shall be immediately terminated if you
                violate these Terms of Use.
              </span>
            </p>
            <p><span></span></p>
            <p><span></span></p>
            <h6><span>GENERAL</span></h6>
            <p><span></span></p>
            <p>
              <span
                >1. This is the official website for all accredited affiliates
                of CadLands.com.
              </span>
            </p>
            <p><span></span></p>
            <p>
              <span
                >2. Your status as an affiliate is not yet confirmed until you
                pay the sign-up fee (N10,000) as directed on the website.</span
              >
            </p>
            <p><span></span></p>
            <p>
              <span
                >3. As a new affiliate, you are to sign up under an already
                accredited affiliate or a staff of the company, who will teach
                you some of the basic things you need to know about the real
                estate business and the work culture/system at CadLands.</span
              >
            </p>
            <p><span></span></p>
            <p>
              <span
                >4. Your job as an affiliate is to canvass for clients directly
                or indirectly through other people and send them to the company.
                A good way to start is by sharing the posts from our official
                social media pages. You can also post on your social media
                profiles regularly or seek out interested clients offline.</span
              >
            </p>
            <p><span></span></p>
            <p>
              <span
                >5. As a new affiliate registered under an already accredited
                affiliate (referee), the referee is responsible for you and your
                clients. This simply means that it is the duty of your referee
                to handle further talks with your client, answer any questions
                you or your client may have, take you and/or your client to the
                site, and close the sale.</span
              >
            </p>
            <p><span></span></p>
            <p>
              <span
                >6. As an affiliate, you are entitled to a 10% commission on all
                sales. This will reflect on your dashboard once a sale is
                confirmed. You can choose to withdraw all or part of the amount
                at any time.
              </span>
            </p>
            <p><span></span></p>
            <p>
              <span
                >7. As an already accredited affiliate (referee) overseeing one
                or more new affiliates under the company, you are to ensure your
                affiliates sign up with your referral link. For every sale
                closed by an affiliate under you, you are entitled to a 5%
                commission from the company. This does not in any way affect the
                10% commission to be paid to the affiliate. You are also
                entitled to 50% of the sign-up fee paid by every new affiliate
                under you. You can choose to withdraw all or part of the amount
                at any time.
              </span>
            </p>
            <p><span></span></p>
            <p>
              <span
                >8. To be successful as an accredited CadLands affiliate, you
                must have learnt the basics of real estate marketing (including
                the legal implications) and you must have mastered the work
                culture and belief systems at CadLands. At this point, you
                should comfortably handle difficult clients on your own.
              </span>
            </p>
            <p><span></span></p>
            <p>
              <span
                >9. As an affiliate/agent, CadLands (and our clients) reserve
                the express right to take legal actions against you if you are
                found or reported to be going against our belief systems or
                doing anything deemed unethical.</span
              >
            </p>
            <p><span></span></p>
            <p><span></span></p>
            <p>
              <span
                >If you are okay with the information contained within these
                Terms of Use, please proceed to submit your application.</span
              >
            </p>
            <p>
              <button class="btn btn-primary" onclick="window.close()">
                I Understand!
              </button>
            </p>
          </div>
        </div>
      </b-card>
    </div>
</template>

<script>
export default {};
</script>